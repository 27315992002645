import moment from 'moment'
import type IQuestionMapper from '../interfaces/IQuestionMapper'
import type IRequestMapper from '../interfaces/IRequestMapper'
import type IResponseMapper from '../interfaces/IResponseMapper'
import type ProductPage from '../models/ProductPage'
import type SchemeRequest from '../values/SchemeRequest'
import type QuoteRequest from '../values/QuoteRequest'
import AccountRegister from '../values/AccountRegister'
import type Account from '../models/Account'
import { Marketing } from '../models/Account'
import type { PetRisk } from '../models/Risk'
import type IPageMappings from '../interfaces/IPageMappings'
import type { IQuestionAnswer } from '../values/PageAnswers'
import PageAnswers, { QuestionAnswer } from '../values/PageAnswers'
import type Address from '../models/Address'
import QuestionEnum from '../enums/QuestionEnum'
import PetQuoteRequest, { PetMTAQuoteRequest } from '../values/PetQuoteRequest'
import DLDCommonValuesEnum from '../enums/DLDCommonValuesEnum'
import UnkownQuestionSetError from '../error/UnknownQuestionSetError'
import type Pet from '../values/Pet'
import BaseMapper from './BaseMapper'
import type {
  DateQuestion,
  NumberQuestion,
  SelectQuestion,
  SubPagesQuestion,
  TextQuestion,
} from '@/view-models/Question'
import ContextLevel from '@/services/enums/ContextLevel'
import Routes from '@/constants/Routes'
import type { Dictionary } from '@/types'
import type Page from '@/view-models/Page'

export default class TractiveMapper extends BaseMapper implements IQuestionMapper, IRequestMapper, IResponseMapper, IPageMappings {
  nbPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MultiPet_Pet_Details_CustomerFacing: { route: Routes.petDetails, contextLevel: ContextLevel.preQuote },
    MultiPet_Proposer_Details_CustomerFacing: { route: Routes.proposerDetails, contextLevel: ContextLevel.preQuote },
    MultiPet_Cover_Details_CustomerFacing: { route: Routes.coverDetails, contextLevel: ContextLevel.preQuote },
    MultiPet_Premium_CustomerFacing: { route: Routes.premium, contextLevel: ContextLevel.quote },
    MultiPet_Summary_CustomerFacing: { route: Routes.quoteSummary, contextLevel: ContextLevel.quote },
    MultiPet_Assumptions_CustomerFacing: { route: Routes.assumptions, contextLevel: ContextLevel.quote },
    MultiPet_Declarations_CustomerFacing: { route: Routes.declarations, contextLevel: ContextLevel.quote },
    MultiPet_Payment: { route: Routes.payment, contextLevel: ContextLevel.quote },
    MultiPet_PaymentConfirmation: { route: Routes.paymentConfirm, contextLevel: ContextLevel.policy },
  }

  mtaPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
    MultiPet_Pet_Details_CustomerFacing_MTA: { route: Routes.mtaPetDetails, contextLevel: ContextLevel.mtaPreQuote },
    MultiPet_Proposer_Details_CustomerFacing_MTA: { route: Routes.mtaProposerDetails, contextLevel: ContextLevel.mtaPreQuote },
    MultiPet_Cover_Details_CustomerFacing_MTA: { route: Routes.mtaCoverDetails, contextLevel: ContextLevel.mtaPreQuote },
    MultiPet_Premium_CustomerFacing_MTA: { route: Routes.mtaPremium, contextLevel: ContextLevel.mtaQuote },
    MultiPet_Assumptions_CustomerFacing_MTA: { route: Routes.mtaAssumptions, contextLevel: ContextLevel.mtaQuote },
    MultiPet_Declarations_CustomerFacing_MTA: { route: Routes.mtaDeclarations, contextLevel: ContextLevel.mtaQuote },
    MultiPet_Payment: { route: Routes.mtaPayment, contextLevel: ContextLevel.mtaQuote },
    MultiPet_PaymentConfirmation: { route: Routes.mtaConfirm, contextLevel: ContextLevel.mtaTakenUp },
  }

  renewalPageMappings: Dictionary<{ route: Routes, contextLevel: ContextLevel }> = {
  }

  setupPage(setId: string, productPage: ProductPage, answers: IQuestionAnswer[], affiliateId?: number, parent?: SubPagesQuestion): Page {
    const mappedPage = this.mapProductPageToPage(productPage)
    return this.setupDependencies(setId, mappedPage, parent)
  }

  setupDependencies(setId: string, mappedPage: Page, parent?: SubPagesQuestion): Page {
    switch (setId) {
      case 'MultiPet_Pet_Details_CustomerFacing':
      case 'MultiPet_Pet_Details_CustomerFacing_MTA':
        return mappedPage
      case 'MultiPet_Pet_Details_CustomerFacing_Pet':
      case 'MultiPet_Pet_Details_CustomerFacing_Pet_MTA':
        return this.setUpPetComponentDependencies(mappedPage, parent)
      case 'MultiPet_Proposer_Details_CustomerFacing':
      case 'MultiPet_Proposer_Details_CustomerFacing_MTA':
        return this.setUpProposerDependencies(mappedPage)
      case 'MultiPet_Cover_Details_CustomerFacing':
      case 'MultiPet_Cover_Details_CustomerFacing_MTA':
      case 'MultiPet_Premium_CustomerFacing':
      case 'MultiPet_Premium_CustomerFacing_MTA':
      case 'MultiPet_Summary_CustomerFacing':
      case 'MultiPet_Assumptions_CustomerFacing':
      case 'MultiPet_Assumptions_CustomerFacing_MTA':
      case 'MultiPet_Declarations_CustomerFacing':
      case 'MultiPet_Declarations_CustomerFacing_MTA':
      case 'MultiPet_Payment':
      case 'MultiPet_PaymentConfirmation':
      case 'MultiPet_AggregatorLanding':
      case 'Account':
        return mappedPage
      default:
        throw new UnkownQuestionSetError(setId)
    }
  }

  private setUpPetComponentDependencies(page: Page, _parent?: SubPagesQuestion): Page {
    const questions = page.sections.flatMap(s => s.questions)
    const petType = questions.find(q => q.name === QuestionEnum.petType) as SelectQuestion<string, string>
    const petName = questions.find(q => q.name === QuestionEnum.name) as TextQuestion
    const gender = questions.find(q => q.name === QuestionEnum.gender)
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.petDateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-4, 'days').toDate()
    const catType = questions.find(q => q.name === QuestionEnum.catType) as SelectQuestion<string, string>
    const dogType = questions.find(q => q.name === QuestionEnum.dogType) as SelectQuestion<string, string>
    const costOfPet = questions.find(q => q.name === QuestionEnum.costOfPet) as NumberQuestion
    const catBreed = questions.find(q => q.name === QuestionEnum.catBreed) as SelectQuestion<string, string>
    catBreed.options = catBreed.options.filter(d => d.value !== DLDCommonValuesEnum.CatBreeds.Moggie).sort((a, b) => ((a.name > b.name) ? 1 : -1))
    const dogBreed = questions.find(q => q.name === QuestionEnum.dogBreed) as SelectQuestion<string, string>
    const hasBeenNeutered = questions.find(q => q.name === QuestionEnum.hasBeenNeutered)
    const hasAttacked = questions.find(q => q.name === QuestionEnum.hasAttacked) as SelectQuestion<string, string>
    const coverPreExistingConditions = questions.find(q => q.name === QuestionEnum.coverPreExistingConditions) as SelectQuestion<string, string>
    const hasCausedAccidentOrLegal = questions.find(q => q.name === QuestionEnum.hasCausedAccidentOrLegal) as SelectQuestion<string, string>
    const trackerUniqueID = questions.find(q => q.name === QuestionEnum.trackerUniqueID) as TextQuestion

    petName.maxlength = 30
    trackerUniqueID.maxlength = 8

    catType.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Cat
    catBreed.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Cat && catType.value === DLDCommonValuesEnum.CatType.Pedigree

    dogType.isVisible = hasCausedAccidentOrLegal.isVisible = hasAttacked.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Dog
    dogBreed.isVisible = (): boolean => petType.value === DLDCommonValuesEnum.PetType.Dog && dogType.value !== null

    costOfPet.min = 0
    costOfPet.max = 99999

    petName!.dependentQuestions = [petType!]
    petType!.dependentQuestions = [petName!]
    gender!.dependentQuestions = [petName!]
    dateOfBirth!.dependentQuestions = [petName!]
    catType!.dependentQuestions = [petType!, petName!]
    dogType!.dependentQuestions = [petType!, petName!]
    costOfPet!.dependentQuestions = [petName!]
    hasBeenNeutered!.dependentQuestions = [petName!]
    hasAttacked!.dependentQuestions = [petName!]
    coverPreExistingConditions!.dependentQuestions = [petName!]
    hasCausedAccidentOrLegal!.dependentQuestions = [petName!]

    return page
  }

  private setUpProposerDependencies(page: Page): Page {
    const questions = page.sections.flatMap(s => s.questions)

    // date of birth
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
    dateOfBirth.maxDate = () => moment().add(-18, 'years').toDate()

    // effective date
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as DateQuestion
    effectiveDateQuestion.minDate = () => moment().toDate()
    effectiveDateQuestion.maxDate = () => moment().add(31, 'days').toDate()

    return page
  }

  getSchemeRequest(_questions: IQuestionAnswer[]): SchemeRequest {
    throw new Error('Method not implemented.')
  }

  validate(_questions: IQuestionAnswer[]): boolean {
    throw new Error('Method not implemented. Tractive does not use custom validation.')
  }

  getQuoteRequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    // Pets
    const petsAnswers = questions.find(q => q.name === QuestionEnum.pets) as QuestionAnswer<number>
    const pets: Pet[] = []

    for (let i = 0; i < petsAnswers.subPageAnswers.length; i += 1) {
      const subPage = petsAnswers.subPageAnswers[i]
      const petType = subPage.answers.find(q => q.name === QuestionEnum.petType) as QuestionAnswer<string>
      const name = subPage.answers.find(q => q.name === QuestionEnum.name) as QuestionAnswer<string>
      const dateOfBirth = subPage.answers.find(q => q.name === QuestionEnum.petDateOfBirth) as QuestionAnswer<Date>
      const petGender = subPage.answers.find(q => q.name === QuestionEnum.gender) as QuestionAnswer<string>
      const costOfPet = subPage.answers.find(q => q.name === QuestionEnum.costOfPet) as QuestionAnswer<number>
      const catBreed = subPage.answers.find(q => q.name === QuestionEnum.catBreed) as QuestionAnswer<string>
      const dogBreed = subPage.answers.find(q => q.name === QuestionEnum.dogBreed) as QuestionAnswer<string>
      const dogType = subPage.answers.find(q => q.name === QuestionEnum.dogType) as QuestionAnswer<string>
      const catType = subPage.answers.find(q => q.name === QuestionEnum.catType) as QuestionAnswer<string>
      const hasBeenNeutered = subPage.answers.find(q => q.name === QuestionEnum.hasBeenNeutered) as QuestionAnswer<string>
      const hasAttacked = subPage.answers.find(q => q.name === QuestionEnum.hasAttacked) as QuestionAnswer<string>
      const coverPreExistingConditions = subPage.answers.find(q => q.name === QuestionEnum.coverPreExistingConditions) as QuestionAnswer<string>
      const hasCausedAccidentOrLegal = subPage.answers.find(q => q.name === QuestionEnum.hasCausedAccidentOrLegal) as QuestionAnswer<string>
      const trackerUniqueId = subPage.answers.find(q => q.name === QuestionEnum.trackerUniqueID) as QuestionAnswer<string>

      if (petType.value === DLDCommonValuesEnum.PetType.Cat && catType.value === DLDCommonValuesEnum.CatType.Moggie)
        catBreed.value = DLDCommonValuesEnum.CatBreeds.Moggie

      pets.push({
        petType: petType.value!,
        name: name.value!,
        gender: petGender.value!,
        dateOfBirth: dateOfBirth.value!,
        petSubType: petType.value! === DLDCommonValuesEnum.PetType.Cat ? catType.value! : dogType.value!,
        breed: petType.value! === DLDCommonValuesEnum.PetType.Cat ? catBreed.value! : dogBreed.value!,
        size: null,
        costOfPet: costOfPet.value!,
        hasBeenNeutered: hasBeenNeutered.value! === DLDCommonValuesEnum.Boolean.Yes,
        hasBeenChipped: true,
        hasUpToDateVaccinations: true,
        proposerOwnsPet: true,
        hasAttacked: petType.value! === DLDCommonValuesEnum.PetType.Dog && hasAttacked.value! === DLDCommonValuesEnum.Boolean.Yes,
        everSeenVetForIllness: false,
        isAwaitingReview: null,
        coverPreExistingConditions: coverPreExistingConditions.value! === DLDCommonValuesEnum.Boolean.Yes,
        veriskResponse: null,
        dominantBreed: null,
        dominantBreedSize: null,
        hasCausedLegalIncident: petType.value! === DLDCommonValuesEnum.PetType.Dog && hasCausedAccidentOrLegal.value! === DLDCommonValuesEnum.Boolean.Yes,
        trackerUniqueId: trackerUniqueId.value!,
      })
    }

    // Proposer
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const address = questions.find(q => q.name === QuestionEnum.proposerAddress) as QuestionAnswer<Address>
    const referralSource = questions.find(q => q.name === QuestionEnum.referralSource) as QuestionAnswer<number>
    const coverLevel = questions.find(q => q.name === QuestionEnum.coverLevelOrType) as QuestionAnswer<string>

    // Cover
    const effectiveDateQuestion = questions.find(q => q.name === QuestionEnum.effectiveDate || q.name === QuestionEnum.mtaEffectiveDate) as QuestionAnswer<Date>

    const quoteRequest = new Type()

    if (!(quoteRequest instanceof PetQuoteRequest || quoteRequest instanceof PetMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof quoteRequest}) for Tractive.`)

    quoteRequest.businessSourceId = DLDCommonValuesEnum.BusinessSourceEnum.AggregatorID

    // Pets
    quoteRequest.pets = pets

    // Proposer
    quoteRequest.proposer.title = DLDCommonValuesEnum.Title.Mx
    quoteRequest.proposer.firstName = firstName.value!
    quoteRequest.proposer.lastName = lastName.value!
    quoteRequest.proposer.dateOfBirth = dateOfBirth.value!
    quoteRequest.proposer.address = address.value!
    quoteRequest.proposer.ukResident = null
    quoteRequest.proposer.usedForBreedingOrConnectedToBusiness = false

    // Cover
    quoteRequest.effectiveDate = effectiveDateQuestion.value!
    quoteRequest.businessSourceId = referralSource?.value
    quoteRequest.address = address.value!
    quoteRequest.coverLevelOrType = coverLevel?.value || null

    return quoteRequest
  }

  getMTARequest<T extends QuoteRequest>(questions: IQuestionAnswer[], Type: new () => T): T {
    const mtaRequest = this.getQuoteRequest(questions, Type)

    if (!(mtaRequest instanceof PetMTAQuoteRequest))
      throw new Error(`Unsupported quote request (${typeof mtaRequest}) for Tractive`)

    const reasonForChange = questions.find(q => q.name === QuestionEnum.reasonForChange) as QuestionAnswer<string>
    mtaRequest.reasonForChange = reasonForChange.value!

    return mtaRequest
  }

  getAccountRegisterRequest(questions: IQuestionAnswer[], affiliateId: number | null): AccountRegister | null {
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail) as QuestionAnswer<string>
    const dob = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address || q.name === QuestionEnum.proposerAddress) as QuestionAnswer<Address>

    if (!firstName?.value || !lastName?.value || !email?.value || !address?.value)
      return null

    const request = new AccountRegister()
    request.title = DLDCommonValuesEnum.Title.Mx
    request.firstName = firstName.value!
    request.lastName = lastName.value!
    request.email = email.value!
    request.dateOfBirth = dob.value!
    request.address = address.value!
    request.homePhone = phoneNumber.value!
    request.mobilePhone = phoneNumber.value!

    request.marketing = new Marketing()
    request.marketing.isEmailOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
    request.marketing.isTelephoneOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    request.marketing.isSMSOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
    request.marketing.isPostOptIn = marketing?.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1

    request.affiliateId = affiliateId

    return request
  }

  updateAccountFromQuestions(account: Account, questions: IQuestionAnswer[]): Account {
    const firstName = questions.find(q => q.name === QuestionEnum.firstname) as QuestionAnswer<string>
    const lastName = questions.find(q => q.name === QuestionEnum.lastName) as QuestionAnswer<string>
    const email = questions.find(q => q.name === QuestionEnum.customerEmail || q.name === QuestionEnum.email) as QuestionAnswer<string>
    const phoneNumber = questions.find(q => q.name === QuestionEnum.mobilePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber || q.name === QuestionEnum.customerHomePhoneNumber) as QuestionAnswer<string>
    const marketing = questions.find(q => q.name === QuestionEnum.marketing) as QuestionAnswer<string[]>
    const address = questions.find(q => q.name === QuestionEnum.address) as QuestionAnswer<Address>
    const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as QuestionAnswer<Date>

    account.firstName = firstName?.value ?? account.firstName
    account.lastName = lastName?.value ?? account.lastName
    account.email = email?.value ?? account.email
    account.homePhone = phoneNumber?.value ?? account.homePhone
    account.mobilePhone = phoneNumber?.value ?? account.mobilePhone
    account.telephone = phoneNumber?.value ?? account.telephone
    account.workPhone = phoneNumber?.value ?? account.workPhone
    account.dateOfBirth = dateOfBirth?.value ?? account.dateOfBirth

    if (marketing?.value) {
      account.marketing.isEmailOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Email) !== -1
      account.marketing.isSMSOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.SMS) !== -1
      account.marketing.isPostOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Post) !== -1
      account.marketing.isTelephoneOptIn = marketing.value?.indexOf(DLDCommonValuesEnum.MarketingOption.Telephone) !== -1
    }
    account.address = address?.value ?? account.address

    return account
  }

  mapRiskToAnswers(risk: PetRisk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    const pageAnswers: Dictionary<PageAnswers> = {}

    this.mapAccountToAnswers(pageAnswers, account, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.firstname, risk.proposer.firstName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.lastName, risk.proposer.lastName, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.dateOfBirth, risk.proposer.dateOfBirth, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.address, risk.proposer.address, pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.petsKeptAtProposerAddress, risk.proposer.petsKeptAtProposerAddress ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.ukResident, risk.proposer.ukResident ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.usedForBreedingOrConnectedToBusiness, this.extractNullableValue(risk.proposer.usedForBreedingOrConnectedToBusiness, null, risk.proposer.usedForBreedingOrConnectedToBusiness ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No), pages)

    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coinsuranceVoluntaryDiscount, risk.coInsuranceRule?.id, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.effectiveDate, risk.effectiveDate, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.proposerAddress, risk.address, pages)
    this.createAnswerForQuestion(pageAnswers, QuestionEnum.coverLevelOrType, risk.coverLevelOrType?.uniqueId, pages)

    const pets = this.createAnswerForQuestion(pageAnswers, QuestionEnum.pets, risk.pets.length, pages)!
    pets.forEach((pap) => {
      for (let i = 0; i < risk.pets.length; i += 1) {
        const pet = risk.pets[i]
        const petType = new QuestionAnswer<string>(0, QuestionEnum.petType, '', pet.petType.uniqueId)
        const name = new QuestionAnswer<string>(0, QuestionEnum.name, '', pet.name)
        const petDateOfBirth = new QuestionAnswer<Date>(0, QuestionEnum.petDateOfBirth, '', pet.dateOfBirth)
        const petGender = new QuestionAnswer<string>(0, QuestionEnum.gender, '', pet.gender.uniqueId)
        const costOfPet = new QuestionAnswer<number>(0, QuestionEnum.costOfPet, '', pet.costOfPet)
        const sizeOfPet = new QuestionAnswer<string>(0, QuestionEnum.sizeOfPet, '', pet.size?.uniqueId)
        const subType = new QuestionAnswer<string>(0, pet.petType.uniqueId === DLDCommonValuesEnum.PetType.Dog ? QuestionEnum.dogType : QuestionEnum.catType, '', pet.petSubType.uniqueId)
        const breed = new QuestionAnswer<string>(0, pet.petType.uniqueId === DLDCommonValuesEnum.PetType.Dog ? QuestionEnum.dogBreed : QuestionEnum.catBreed, '', pet.breed.uniqueId)
        const hasBeenNeutered = new QuestionAnswer<string>(0, QuestionEnum.hasBeenNeutered, '', pet.hasBeenNeutered ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasBeenChipped = new QuestionAnswer<string>(0, QuestionEnum.hasBeenChipped, '', pet.hasBeenChipped ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasUptoDateVaccinations = new QuestionAnswer<string>(0, QuestionEnum.hasUptoDateVaccinations, '', pet.hasUpToDateVaccinations ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const proposerOwnsPet = new QuestionAnswer<string>(0, QuestionEnum.proposerOwnsPet, '', pet.proposerOwnsPet ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const hasAttacked = new QuestionAnswer<string>(0, QuestionEnum.hasAttacked, '', pet.hasAttacked ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const everSeenVetForIllness = new QuestionAnswer<string>(0, QuestionEnum.everSeenVetForIllness, '', pet.everSeenVetForIllness ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const isAwaitingReview = new QuestionAnswer<string>(0, QuestionEnum.isAwaitingReview, '', pet.isAwaitingReview ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const coverPreExistingConditions = new QuestionAnswer<string>(0, QuestionEnum.coverPreExistingConditions, '', pet.coverPreExistingConditions ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const verisk = new QuestionAnswer<string>(0, QuestionEnum.verisk, '', pet.veriskDetail?.veriskData)
        const hasCausedAccidentOrLegal = new QuestionAnswer<string>(0, QuestionEnum.hasCausedAccidentOrLegal, '', pet.hasCausedLegalIncident ? DLDCommonValuesEnum.Boolean.Yes : DLDCommonValuesEnum.Boolean.No)
        const trackerUniqueId = new QuestionAnswer<string>(0, QuestionEnum.trackerUniqueID, '', pet.trackerUniqueId)
        const petPage = new PageAnswers(`Pet ${i + 1}`, [petType, name, petDateOfBirth, petGender, costOfPet, sizeOfPet, subType, breed, hasBeenNeutered, hasBeenChipped, hasUptoDateVaccinations, proposerOwnsPet, hasAttacked, everSeenVetForIllness, coverPreExistingConditions, isAwaitingReview, verisk, hasCausedAccidentOrLegal, trackerUniqueId])
        pap.subPageAnswers.push(petPage)
      }
    })

    return pageAnswers
  }
}
