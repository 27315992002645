import type {
  JsonCustomConvert,
} from 'json2typescript'
import {
  JsonConvert,
  JsonConverter,
  ValueCheckingMode,
} from 'json2typescript'
import SchemeQuoteResult, { CarSchemeQuoteResult, PetSchemeQuoteResult, TouringCaravanSchemeQuoteResult } from '@/services/models/SchemeQuoteResult'

@JsonConverter
export default class SchemeQuoteResultsConverter implements JsonCustomConvert<SchemeQuoteResult[]> {
  serialize(result: SchemeQuoteResult[]): string {
    return result.toString()
  }

  deserialize(result: any[]): SchemeQuoteResult[] {
    const converter = new JsonConvert()
    converter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    if (result.length === 0)
      return []

    if (Object.keys(result[0]).includes('noClaimsProtected'))
      return converter.deserializeArray(result, CarSchemeQuoteResult)

    if (Object.keys(result[0]).includes('compulsoryExcess') && Object.keys(result[0]).includes('voluntaryExcess'))
      return converter.deserializeArray(result, TouringCaravanSchemeQuoteResult)

    if (Object.keys(result[0]).includes('compulsoryExcess'))
      return converter.deserializeArray(result, PetSchemeQuoteResult)

    return converter.deserializeArray(result, SchemeQuoteResult)
  }
}
